<template>
  <div>
    <div class="container">
      <!-- 获客/分享趋势图表 -->
      <div class="trend-content" :style="{'height': chartData.length > 0 ? '500px' : '100px'}">
        <div v-if="chartData.length > 0" id="chart-bar" class="chart-bar-canvas"></div>
        <p v-else class="chart-bar-text">暂无数据，请稍后查看</p>
      </div>

      <!-- 数据概况 -->
      <div class="header">
        <h2 style="margin-bottom: 10px">数据概况&nbsp;&nbsp;&nbsp;&nbsp;
          <div class="header-item">
            <el-input v-model="searchName" clearable
              placeholder="输入姓名搜索" suffix-icon="el-icon-search"></el-input>
          </div>
        </h2>

        <!-- 任务完成列表 -->
        <el-table ref="multipleTable" style="width: 100%; margin-top: 10px"
          header-row-class-name="table-header-style"
          :data="listData.filter(e => !searchName || (e.name && e.name.indexOf(searchName) != -1))"
          :header-cell-style="{'background-color': 'rgba(249, 249, 249)'}">
          <!-- 任务名称 -->
          <el-table-column label="任务名称" prop="task_name"> </el-table-column>
          <!-- 领取人 -->
          <el-table-column label="领取人" prop="name"></el-table-column>
          <!-- 任务进度/任务目标 -->
          <el-table-column label="任务进度/任务目标" prop="detailedSource">
            <template slot-scope="scope">{{computedTaskCondition(scope.row)}}</template>
          </el-table-column>
          <!-- 更新时间 -->
          <el-table-column label="更新时间">
            <template slot-scope="scope">
              <p>{{ scope.row.updateTime | dateFormat('YYYY-MM-DD HH:mm:ss') }}</p>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      option: {
        title: {text: '任务完成情况', left: 'center'},
        tooltip: {
          trigger: 'axis',
          axisPointer: {type: 'shadow'}
        },
        grid: {left: '5%', right: '5%'},
        xAxis: {type: 'category'},
        yAxis: {type: 'value'},
        dataZoom: [],
        series: []
      },
      chartData: [],       // 图表数据
      searchName: '',      // 搜索姓名
      listData: [],        // 列表数据
    }
  },
  created(){
    this.getTaskStatList();
  },
  mounted(){
    this.getTaskStat();
  },
  methods: {
    // 查看任务数据图表
    async getTaskStat(){
      let res = {};
      let serverRes = await this.$ZGManager.getTaskStat(this.$route.query.id);
      if(serverRes.status != 200 || serverRes.data.length < 1) {
        res.data = [
          {
            time: '2012-10-13',
            count: 1
          },
          {
            time: '2012-10-14',
            count: 3
          },
          {
            time: '2012-10-15',
            count: 3
          },
          {
            time: '2012-10-16',
            count: 4
          },
          {
            time: '2012-10-18',
            count: 3
          },
        ];
      }else{
        res.data = serverRes.data;
      }
      
      // 整合缩放条数据
      let dataZoom = {xAxisIndex: [0]};
      if(res.data.length > 0){
        dataZoom.startValue = res.data[0].time;
      }
      if(res.data.length >= 10){
        dataZoom.endValue = res.data[9].time;
      }else{
        dataZoom.endValue = res.data[res.data.length - 1].time;
      }
      this.option.dataZoom.push(dataZoom);

      // 整合X和Y轴数据
      let axisData = {
        name: '线索',
        type: 'bar',
        barWidth: '10%',
        itemStyle: {normal: {color: '#1a9afd'}},
      }
      res.data.map(e => this.chartData.push([e.time, e.count]));
      axisData.data = this.chartData;
      this.option.series.push(axisData);

      this.$nextTick((e) => {
        let myChart = this.$allecharts.init(document.getElementById('chart-bar'));
        myChart.setOption(this.option);
      });
    },

    // 查看任务数据列表
    async getTaskStatList(){
      let res = await this.$ZGManager.getTaskStatList(this.$route.query.id);
      if(res.status != 200) return;
      this.listData = res.data;
    },

    // 计算任务完成情况
    computedTaskCondition(data){
      let goal = data.target_task == 0 ? '新增浏览数' : '新增线索数';
      let finish = data.num || 0;
      let all = data.times || 0;
      return `${goal} ${finish}/${all}`;
    }
  }
}
</script>

<style lang="scss" scoped>
.container{
  background-color: #fff;
  padding: 15px 10px;
  overflow: hidden;
}
.header{
  position: relative;
}
.header-item{
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}
.trend-content{
  width: calc(100% - 30px);
  height: 500px;
  padding: 30px 15px;
  margin-bottom: 30px;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  .chart-bar-canvas{
    width: 100%;
    height: 100%;
  }
  .chart-bar-text{
    color: #909399;
    line-height: 100px;
    text-align: center;
  }
}
.page-pagination{
  float: right; 
  margin-top: 15px;
}
</style>